export default function isNumberStrict(
  value: unknown
): asserts value is number {
  if (typeof value !== "number" || Number.isNaN(value)) {
    throw new Error("Value is not a number");
  }
}

export const isNumber = (value: unknown) => {
  return typeof value === "number" && !Number.isNaN(value);
};
