import Image from "next/image";
import st from "../styles/items/ValueDisplay.module.scss";
import { classnames, mergeClasses } from "@lib/tools/helpers";
import { memo } from "react";
import useWallet from "@hooks/useWallet";
const useWalletCode = () => {
  const {
    walletSetting
  } = useWallet();
  return walletSetting;
};
interface CurrencyIconsProps {
  code?: string;
  size?: "large" | "mid" | "small" | false;
  classItem?: string;
  emptyValue?: boolean;
  greyEmpty?: boolean;
  textIcon?: boolean;
  symbolColor?: string;
}
function CurrencyIcons({
  code,
  size = false,
  classItem = "",
  emptyValue,
  greyEmpty = false,
  textIcon = false,
  symbolColor
}: CurrencyIconsProps) {
  const wallet = useWalletCode()?.toLowerCase();
  const currencyCode = code || wallet || "usd";
  const url = `https://cdn.rainbet.com/currencies/${currencyCode?.toLowerCase()}.svg`;
  const classes = mergeClasses(classnames(st, "currency-icon", size), classItem && classItem);
  if (!currencyCode) return null;
  if (textIcon) {
    const currencySymbols: Record<string, string> = {
      aud: "A$",
      brl: "R$",
      cad: "C$",
      eur: "€",
      gbp: "£",
      usd: "$",
      jpy: "¥"
    };
    console.log("symbolColor", symbolColor);
    const symbol = currencySymbols[currencyCode.toLowerCase()] || "$";
    return <span className={st["text-currency"]} style={symbolColor ? {
      color: symbolColor
    } : undefined}>
        {symbol}
      </span>;
  }
  return <div {...classes} data-sentry-component="CurrencyIcons" data-sentry-source-file="CurrencyIcons.tsx">
      <Image alt={currencyCode?.toUpperCase?.()} src={url} width={25} height={25} style={{
      maxWidth: "100%",
      height: "auto",
      ...(emptyValue && greyEmpty && {
        filter: "opacity(66%) saturate(10%) brightness(120%)"
      })
    }} data-sentry-element="Image" data-sentry-source-file="CurrencyIcons.tsx" />
    </div>;
}
export default memo(CurrencyIcons);