import useWallet, { Currency } from "@hooks/useWallet";
import { formatValueDisplay } from "@lib/tools/convert";
import { classnames, mergeClasses, useLocale } from "@lib/tools/helpers";
import isNumberStrict from "@lib/tools/is-number";
import { sBig } from "@lib/tools/numberHelpers";
import st from "@styles/items/ValueDisplay.module.scss";
import { CSSProperties, memo, useMemo } from "react";
import CurrencyIcons from "./CurrencyIcons";
interface ShouldConvertOptions {
  raw?: boolean;
  customFiatRate?: number | false;
}
export const useShouldConvert = (currency: Currency, options?: ShouldConvertOptions) => {
  const {
    raw,
    customFiatRate
  } = options || {};
  const {
    activeRate
  } = useWallet();
  const shouldConvert = useMemo(() => currency?.rate !== activeRate?.rate, [activeRate?.rate, currency?.rate]);
  return raw || customFiatRate ? false : shouldConvert;
};
type ValueDisplayProps = Readonly<{
  amount?: number;
  size?: "small" | "mid" | "large";
  customClass?: string;
  classType?: string | string[];
  iconCustomClass?: string;
  icon?: boolean;
  decimals?: number;
  raw?: boolean;
  iconOnly?: boolean;
  currencyCode?: string;
  textSize?: number | false;
  change?: (value: string) => string;
  greyIconOnEmpty?: boolean;
  customFiatRate?: number | false;
  debug?: boolean;
  textIcon?: boolean;
  symbolPosition?: boolean;
  style?: CSSProperties;
  rounding?: "up" | "down";
  symbolColor?: string;
}>;
function ValueDisplay({
  amount = 0,
  size = "mid",
  customClass,
  classType,
  iconCustomClass,
  icon = false,
  raw = false,
  iconOnly = false,
  currencyCode,
  textSize = false,
  change,
  greyIconOnEmpty = false,
  customFiatRate = false,
  debug,
  textIcon = false,
  symbolPosition = true,
  style,
  rounding,
  decimals: decimalsProp,
  symbolColor
}: ValueDisplayProps) {
  const {
    activeRate,
    currencies
  } = useWallet();
  const locale = useLocale();
  const currency = currencies?.[currencyCode?.toUpperCase?.()];
  const shouldConvert = useShouldConvert(currency, {
    raw,
    customFiatRate
  });
  const decimals = decimalsProp ?? activeRate?.display?.decimals ?? 2;
  const dolarAmount = useMemo(() => {
    try {
      const rate = currency?.rate || 1;
      const n = shouldConvert ? sBig(+amount).div(sBig(rate)).toNumber() : +amount;
      isNumberStrict(n);
      return n;
    } catch {
      return +amount;
    }
  }, [amount, shouldConvert, currency]);
  const formattedAmount = useMemo(() => {
    if (iconOnly) return "";
    const rate = !shouldConvert && currencyCode && typeof currencyCode === "string" ? currency : activeRate;
    const formatOptions = {
      rate: customFiatRate ? {
        rate: customFiatRate
      } : rate,
      raw: !customFiatRate && (!shouldConvert || raw),
      decimals,
      locale,
      symbol: symbolPosition,
      debug,
      symbolColor,
      ...(rounding && {
        round: {
          direction: rounding
        }
      })
    };
    const baseFormatted = formatValueDisplay(dolarAmount, formatOptions);
    return change ? change(baseFormatted) : baseFormatted;
  }, [activeRate, change, currency, currencyCode, customFiatRate, debug, decimals, iconOnly, locale, dolarAmount, raw, rounding, shouldConvert, symbolPosition, symbolColor]);
  const textStyle = useMemo(() => textSize ? {
    fontSize: textSize + "px"
  } : undefined, [textSize]);
  const containerClassName = useMemo(() => mergeClasses(classnames(st, textIcon ? "text-display" : "value-display", classType), customClass), [textIcon, classType, customClass]);
  const valueClassName = useMemo(() => classnames(st, "value", textIcon ?? "value-number-display"), [textIcon]);
  return <div {...containerClassName} style={{
    ...style,
    ...textStyle
  }} data-sentry-component="ValueDisplay" data-sentry-source-file="ValueDisplay.tsx">
      {icon && <CurrencyIcons size={size} classItem={iconCustomClass} code={shouldConvert && !raw ? undefined : currencyCode || ""} emptyValue={+amount === 0} greyEmpty={greyIconOnEmpty} textIcon={textIcon} />}

      {formattedAmount && <span {...valueClassName} dangerouslySetInnerHTML={{
      __html: formattedAmount
    }} />}
    </div>;
}
export default memo(ValueDisplay);